<template>
  <div>
  <div class="row gap-5">
    <div class="block   w-full text-right mr-2">
      <router-link  to="/docker/list" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست کلاسترها</router-link>

      </div>
    <div class="bg-h-primary-light w-full  lg:w-1/4 p-5 rounded-lg border border-dashed border-white border-opacity-25 shadow-lg">
      <DockerInfoCard v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo"   :key="cloudInfo.id"></DockerInfoCard>

    </div>

    <div class="w-full lg:w-2/3">
      <div class="float-right hidden lg:block">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
      <b-tabs content-class="mt-3" refs="tabs" >
        <b-tab title="لیست نودها" active href="resource">
          <Nodes v-if="cloudInfo" :plan="plan" @editeCloud="getInfo" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></Nodes>
        </b-tab>

        <!-- <b-tab v-if="cloudInfo.is_vdi != '1'" title="تغییر سیستم عامل" href="os" :active="$route.hash === '#os'" @click="tabHash('os')">
          <div class="d-block md:flex justify-center flex flex-wrap">
            <OsCard @setData="setData" :key="index" v-for="(item, index) in imageList" :osData="item" class=" mr-3 ml-4 my-base vx-col lg:w-1/5 sm:w-1/4"/>
          </div>
        </b-tab>
        <b-tab title="رویدادها" :active="$route.hash === '#events'" href="events" @click="tabHash('events')">
          <vira-tasks v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-tasks>
        </b-tab>
        <b-tab  href="console" title="کنسول" :active="$route.hash === '#console'" @click="tabHash('console')">
          <vira-console v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-console>
        </b-tab>
        <b-tab v-if="cloudInfo.location.country_iso == 'IR' && cloudInfo.is_vdi !== 1 " title="نمودار ترافیک" href="trafficgraph" :active="$route.hash === '#trafficgraph'" @click="tabHash('trafficgraph')">
          <vira-traffic-monitoring v-if="cloudInfo" :plan="plan"  :data="cloudInfo"  :key="cloudInfo.id"></vira-traffic-monitoring>
        </b-tab> -->


      </b-tabs>
      </div>

  </div>

      <div>
      <vs-popup :active.sync="reinstallPrompt" title="نصب مجدد">
        <div  class="con-exemple-prompt">
          <div v-if="smsConfirmStatus">
              <p>کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.</p>
              <vs-input
                class="w-full mb-3"
                v-model="requestCode"
                label-placeholder="کد تایید"
              ></vs-input>
          </div>
          <div v-else>
            <p>آیا از نصب مجدد سیستم عامل  اطمینان دارید </p>
          </div>
          <vs-button
            icon=""
            class="float-right rounded w-25"
            color="primary"
            @click="reinstall()"
            >نصب مجدد</vs-button
          >
        </div>
      </vs-popup>
    </div>

</div>

</template>

<script>
import DockerInfoCard     from './DockerInfoCard.vue'
import OsCard           from './OsCard.vue'
import Nodes     from './components/Nodes.vue'

// import Firewall         from './Firewall.vue'
// import TerminalAccess   from './TerminalAccess.vue'

export default {
  components : {
    DockerInfoCard,
    OsCard,
    Nodes
    // Firewall,
    // TerminalAccess
  },
  data () {
    return {
      cloudId : '',
      cloudInfo : {},
      vm_Traffic: '',
      plan : '',
      imageList : [],
      imageInfo  : null,
      requestCode : '',
      reinstallPrompt : false,
      VDILink:'',
      myloc: ''
    }
  },
  watch : {
    $route () {
      this.cloudId = this.$route.params.id
      this.getInfo()
    }
  },
  computed: {
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }

  },
  methods : {
    tabHash (val) {
      location.hash = val
    },
    editCloud (val) {
      const data = {
        // id : this.cloudId,
        vm_title: val.title,
        vm_vcpu : val.cpu,
        vm_ram : val.ram,
        vm_extra_hard : val.extra_hard
      }
      this.$http.put(`/cloud/vm/${this.cloudId}`, data).then((result) => {
        this.$vs.notify({text:result.data.message})
        this.getInfo()
      }).catch((err) => {
        this.$vs.notify({text: err.response.data.message, color: 'danger'})
      })
    },
    getVMTraffic () {
      this.$http.get(`/cloud/vm/${this.cloudId}/traffic/current`).then((result) => {
        this.vm_Traffic = result.data.params[0]


      }).catch(() => {

      })

    },
    getInfo () {
      this.$http.get(`/cloud/docker/cluster/${this.cloudId}`).then((result) => {
        this.cloudInfo = result.data.params
        // this.getImageList()
      }).catch(() => {

      })
    },
    getPlan () {
      this.$http.get('/cloud/plan').then((result) => {
        this.plan = result.params.data[0]
      }).catch(() => {

      })
    },
    getImageList () {
      this.$vs.loading()
      this.$http
        .get(`/cloud/image/template?type=${this.cloudInfo.vm_type}&node_id=${this.cloudInfo.node_id}`)
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params.data
          this.imageList = data
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'dager',
            title: err.response.data.message
          })
        })
    },
    reInstallRequest () {
      this.$http
        .get(`/cloud/vm/${this.cloudId}/reinstall/request`).then((res) => {
          this.$vs.notify({
            color: 'success',
            text: res.data.message
          })
        }).catch((err) => {
          this.$vs.notify({
            color: 'danger',
            text: err.response.data.message
          })
        })
    },
    reinstall () {

      const data =  {
        vm_id: this.cloudId * 1,
        confirm_code: this.requestCode,
        template_id: this.imageInfo.id,
        template_version: this.imageInfo.versions
      }

      this.$http.post('cloud/vm/reinstall', data).then((res) => {

        this.$vs.notify({
          color: 'success',
          text: res.data.message
        })
        this.$router.go(-1)
        this.reinstallPrompt = false
      }).catch((err) => {
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message
        })
      }).finally(() => {

        this.requestCode = ''
      })
    },
    setData (value) {
      this.imageInfo = value
      if (value.id && value.versions) {
        this.reinstallPrompt = true
        if (this.smsConfirmStatus === 1) {
          this.reInstallRequest()
        }

      } else {
        this.$vs.notify({
          text: 'لطفا سیستم عامل و نسخه را انتخاب کنید',
          color : 'warning'
        })
      }
    },
    getVDILink (id) {
      this.$http.get(`/cloud/vm/vdi/${id}`)
        .then((response) => {
          this.VDILink = response.data.params.url
        })
    }

  },
  created () {
    this.cloudId = this.$route.params.id
    this.getInfo()
    // if (this.is_vdi === 1) {
    //   this.getVDILink(this.cloudId)
    // }
    // this.is_vdi = this.is_vdi
    // this.getVMTraffic()
  }
}
</script>
