<template>
  <div class="d-block w-full">
    <div class="flex w-full justify-between">
      <h3 class="text-right pb-2 " style="font-family: Segoe UI" >کلاستر داکر: {{ title.name }}</h3>
      <!-- <vs-input class="w-11/12 mb-2" size="small" v-if="title.edit" v-model="title.name"></vs-input> -->

      <!-- <vs-button @click="update" class="bg-transparent p-0 m-0 text-xs w-full" v-if="title.edit">اعمال</vs-button> -->
    </div>
    <vs-divider></vs-divider>

    <div class="font-light rounded p-2 pb-0">

      <div class="text-center flex justify-between gap-8 items-center ">
        <span class="mb-4 block"> وضعیت:
          <span v-if="data.nodes[0].node_status_title == 'آماده استفاده'" class="text-h-success "> آنلاین</span>
          <span v-else-if="data.nodes[0].node_status_title == 'خاموش'" class="text-h-danger "> خاموش</span>
        </span>
        <div class="text-center flex justify-between gap-8 items-center ">


          <div>
            <img width="30" class="m-auto d-block"
            :src="`data:image/png;base64,${data.location.country_flag_file.attachment_content}`" />
            <p class="d-block" style="color: #9a9a9a">{{ data.location.country_name }}</p>
          </div>
        </div>
      </div>
      <!-- Machine Detail -->

      <div class="flex justify-center gap-2 items-center ">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer">
          {{ data.total_worker }}
          <span class="text-xs text-white d-block text-center">تعداد ورکر</span>
        </div>
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer" @click="copyToClipboard(data.nodes[0].vm_master_ip)">
          {{ data.nodes[0].vm_master_ip }}
          <span class="text-xs text-white d-block text-center">آی‌پی مستر</span>
        </div>

      </div>


      <vs-divider />
      <!-- <div>
      <span class="text-base block text-left mb-4 mt-5 text-h-success">مبلغ قابل پرداخت</span>
      <div class=" p-2 mb-5">

        <div class="rounded-lg bg-h-primary w-full px-2 pb-4 py-2 text-white text-center">

          <span class="text-lg">
            {{ data.vm_cost_per_hour | money }} تومان/ساعتی
          </span>

        </div>
        <div class="block rounded-lg bg-h-primary-light text-grey w-full text-center px-2 py-1 text-base">
          <span class="">هزینه ماهیانه</span>
          {{ data.vm_cost_per_month | money }} تومان
          <span class="text-xs">(صرفا جهت اطلاع) </span>
        </div>

      </div>
    </div> -->
      <!-- Machine Actions -->
      <div class="flex items-center mt-3 w-full justify-center" v-if="data.vm_status_title !== 'در حال آماده سازی'">

        <div :id="'delete' + data.id" @click="setDeletePrompt(data.id), deletePrompt = true, id = data.id"
          class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
          <b-tooltip variant="info" :target="'delete' + data.id" triggers="hover">
            حذف کلاستر
          </b-tooltip>
          <b-icon class="my-2" scale="2" icon="trash"></b-icon>
        </div>
      </div>
    </div>
    <vs-divider />

    <div class="flex justify-between mt-5 mb-4 items-center" v-if="this.vmTraffic">
      <span class="">مصرف ترافیک ساعت جاری</span>
      <span @click="getVMTraffic()" class="">
        <feather-icon id="traffic" @click="rotation = !rotation" icon="RefreshCwIcon" :class="{ reload: rotation }"
          class="w-6 rotate" />
        <b-tooltip variant="info" target="traffic" triggers="hover">
          تازه سازی مصرف
        </b-tooltip>
      </span>
    </div>


    <!-- Reboot VM -->

    <!-- ShutDown VM -->

    <!-- delete vira cloud prompt -->
    <div>
      <vs-popup :active.sync="deletePrompt" title="حذف  کلاستر">
        <div class="con-exemple-prompt">
          <!-- <div v-if="smsConfirmStatus === 1">
            <p>
              کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.
            </p>
            <vs-input class="w-full mb-3" v-model="requestCode" label-placeholder="کد تایید"></vs-input>
          </div> -->
          <div >
            <p>آیا از حذف اطمینان دارید. ؟</p>
          </div>
          <vs-button icon="delete" class="float-right rounded w-25" color="danger"
            @click="deleteRecord(id)">حذف</vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
export default {
  components: {
    RoundSlider
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      minHard: this.data.vm_hard,
      cpu: this.data.vm_vcpu,
      hard: +this.data.vm_hard,
      ram: this.data.vm_ram,
      verifyoffCode:'',
      vm_Traffic:this.vmTraffic,
      popupActiveReset: false,
      shutdownPrompt: false,
      deletePrompt: false,
      activePrompt : false,
      serverIdreboot: null,
      selectedID: 0,
      title: { name: this.data.group_title, edit: false },
      rotation: false,
      userblur: true,
      passblur: true
    }
  },
  computed: {
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    }
  },
  watch: {
    data () {
      this.cpu = this.data.vm_vcpu
      this.hard = this.data.vm_hard
      this.ram = this.data.vm_ram
      // this.title.name = this.data.template.title
    }

  },
  methods: {

    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    getVMTraffic () {
      this.$http.get(`/cloud/vm/${this.data.id}/traffic/current`).then((result) => {
        this.vm_Traffic = result.data.params[0]


      }).catch(() => {

      })

    },
    deleteRequestCode (vmId)   {
      this.$http
        .get(`/cloud/vm/${vmId}/delete/request`)
        .then((res) => {
          this.$vs.notify({
            color: 'success',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
    },
    deleteRecord (id) {
      this.$http
        .delete(`/cloud/docker/cluster/${id}`, {
          confirm_code: this.requestCode
        })
        .then((res) => {
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف کلاستر داکر'
          })

          this.sweetAlert(['کلاستر حذف شد', 'لیست کلاسترها', '/docker/list'])

        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف کلاستر داکر'
          })
        })
        .finally(() => {
          this.deletePrompt = false
        })
    },

    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
      if (this.smsConfirmStatus === 1) {
        this.deleteRequestCode(d)
      }
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },

    turnOff (id) {
      if (this.data.is_vdi === '0') {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.data.vm_status_title = 'خاموش'

            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.data.vm_status_title = 'خاموش'

            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      }

    },
    reboot (id) {
      this.rebootPrompt = false
      if (this.data.is_vdi === '0') {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.data.vm_status_title = 'آنلاین'

            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.data.vm_status_title = 'آنلاین'
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      }


    },
    handleResetPropmt (item) {
      this.popupActiveReset = true
      this.serverIdreboot = item
    },
    returnBigger (num, max) {
      if (num > max) {
        return num
      } else {
        return max
      }
    },
    checkVal (c) {
      // console.log(c.value)
      if (+c.value < +this.hard) {
        c.value = 100
      }
    },
    update () {
      const emitData = {
        title: this.title.name,
        ram: this.ram,
        cpu: this.cpu,
        extra_hard: (this.hard * 1) - this.data.vm_hard || ''
      }
      this.$emit('editeCloud', emitData)
      this.title.edit = false

    },
    editTodo (title) {
      this.editedTodo = title
    },

    linux (d) {
      if (d === 'Windows') {
        return 'win10'
      }
      return d.toLowerCase()
    }
  }
}
</script>

<style scoped>
  .future-card {
    border-bottom: 2px solid #3d2353 !important;
    border-radius: 0;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
</style>
<style>
.rotate {
    -moz-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
}
.rotate.reload {
    -moz-transform:rotate(360deg);
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.blurtext {
  color: transparent;
  text-shadow: 0 0 8px #f1f1f1;
}
</style>
