<template>
  <div class="">
    <b-card-group class="">
      <b-card class="background text-center border-h-secondary h-64 py-4" border-variant="info" >
        <template>
        <h6 class="mb-0">{{osData.title}}</h6>
      </template>
         <h2  :class="`fo-${linux(osData.title)} text-5xl`"> </h2>
         <!-- <h1 v-if="osData.title === 'Windows'" class="fo-win10 text-4xl"></h1> -->
        <vs-select color="#333" class="w-full" placeholder="  انتخاب نسخه ..." v-model="versionNumber" >
          <vs-select-item  icon="sign" :key="index" :value="item" :text="item" v-for="(item,index) in sampleData.versions" />
        </vs-select>
         <b-button class="mt-2 bg-h-secondary border-0" @click="emitechange">تغییر به این </b-button>
      </b-card>
    </b-card-group>
  </div>
</template>


<script>
import '@jeykeu/font-os/assets/css/font-os.css'
// import { version } from 'vee-validate'
export default{
  props: ['osData', 'versionData'],
  emits: ['setData'],
  data () {
    return {

      sampleData : {},
      versionNumber:'',
      osArray : [],
      activeCard : false,
      selectOs : '',
      versionArray : [],
      emitData :{}
    }
  },

  methods: {
    emitechange () {
      this.emitData.id = this.sampleData.id
      this.emitData.versions = this.versionNumber
      this.$emit('setData', this.emitData)
    },
    linux (d) {
      if (d === 'Windows') {
        return 'win10'
      }

      return d.toLowerCase()
    },

    changeStyle () {
      this.activeCard = !this.activeCard
    }
  },
  created () {
    this.sampleData.versions = this.osData.versions.split(',')
    this.sampleData.id = this.osData.id
    this.sampleData.title = this.osData.title

  }
}
</script>

<style scoped>
.big-font{
  font-size: 50px;
}
.background {
background-color: #383651;
background-image: linear-gradient(315deg, #140838 0%, #0202282a 74%);

}

</style>
